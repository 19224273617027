<template>
    <div class="chart-container" v-if="loaded">
        <line-chart
            style="height: 250px"
            :chartdata="chartdata"
            :options="options"
        />
        <div class="chart-container__stats" v-if="chartHasData">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <div class="chart-container__statsItem">
                        <div class="chart-container__statsItemSquare"></div>
                        <div class="chart-container__statsItemText">
                            <h5>{{$t('Tickets sold')}}</h5>
                            <h4>{{ tickets_count }}</h4>
                        </div>
                    </div>
                    <div class="chart-container__statsItem">
                        <div class="chart-container__statsItemSquare"></div>
                        <div class="chart-container__statsItemText">
                            <h5>{{$t('Sales')}}</h5>
                            <h4>{{ moneyFormat(tickets_amount) }}</h4>
                        </div>
                    </div>
                    <div class="chart-container__statsItem">
                        <div class="chart-container__statsItemSquare"></div>
                        <div class="chart-container__statsItemText">
                            <h5>{{$t('Efficiency')}}</h5>
                            <h4>{{ efficiency }} %</h4>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6">
                    <div class="chart-container__statsItem">
                        <div class="chart-container__statsItemSquare"></div>
                        <div class="chart-container__statsItemText">
                            <h5>{{$t('Number of cinemas')}}</h5>
                            <h4>{{ cinemas_count }}</h4>
                        </div>
                    </div>
                    <div class="chart-container__statsItem">
                        <div class="chart-container__statsItemSquare"></div>
                        <div class="chart-container__statsItemText">
                            <h5>{{$t('Number of movies')}}</h5>
                            <h4>{{ movies_count }}</h4>
                        </div>
                    </div>
                    <div class="chart-container__statsItem">
                        <div class="chart-container__statsItemSquare"></div>
                        <div class="chart-container__statsItemText">
                            <h5>{{$t('Number of sessions')}}</h5>
                            <h4>{{ sessions_count }}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="chart-container__empty" v-else>
            <img src="@/assets/img/folder-empty.svg" alt="svg">
            <p>{{$t('No data found')}}</p>
        </div>
        <div class="mt-3 position-relative">
            <round-chart
                style="height: 330px"
                :chartdata="chartdataPie"
                :options="optionsPie"
                v-if="pieRender"
            />
            <Preloader v-else-if="chartHasData && !pieRender"></Preloader>
            <p class="chart-container__donutCenter"></p>
        </div>

    </div>
    <Preloader v-else></Preloader>
</template>

<script>
import LineChart from "@/plugins/LineChart";
import RoundChart from "@/plugins/RoundChart.js";
import Preloader from "@/components/ui/Preloader"

export default {
    name: "LineChartContainer",
    components: { LineChart, Preloader, RoundChart },
    data: () => ({
        chartPosition: 'right',
        loaded: false,
        chartHasData: false,
        pieRender: false,
        chartdata: {},
        chartdataPie: {},
        options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            tooltips: {
                callbacks: {
                label: (tooltipItem) => {
                    return tooltipItem.yLabel;
                }
              }
            },
             scales: {
                yAxes: [{
                  ticks: {
                    min: 0,
                    beginAtZero: true,
                    callback: function(value, index, values) {
                        if (Math.floor(value) === value) {
                            return value;
                        }
                    }
                  }
                }]
              }
        },
        optionsPie: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                position: 'right'
                /*labels: {
                  generateLabels: function(chart) {
                    var data = chart.data;
                    if (data.labels.length && data.datasets.length) {
                        return data.labels.map(function(label, i) {
                            var ds = data.datasets[0];
                            let fill = data.datasets[0].backgroundColor[i]
                            return {
                                text: label + ' (' + ds.data[i] + ')',
                                fillStyle: fill,
                                strokeStyle: fill,
                            };
                        });
                    }
                    return [];
                  }
                }*/
            },
        },
        cinemas_count: '',
        halls_count: '',
        movies_count: '',
        tickets_amount: '',
        tickets_count: '',
        sessions_count: '',
        efficiency: '',
    }),

    computed: {
        getAppliedFilters() {
            return this.$store.getters.getAppliedFilters
        },
        getSortByType() {
            return this.$store.getters.getSortByType
        },
        getNewAnalytic() {
            return this.$store.getters.getNewAnalytic
        },
    },
    watch: {
        getAppliedFilters(allFilters) {
            if (allFilters.format) {
                this.getAnalytics(allFilters)
            }
        },
        getNewAnalytic(v) {
            if (v) {
                this.getAnalytics(this.getAppliedFilters)
                this.$store.dispatch('setNewAnalytic', false)
            }
        }
    },

    mounted() {
        this.$store.commit('addAllFilters', {
            format: 'day',
            date_from: new Date().toISOString().slice(0, 10),
            date_to: new Date().toISOString().slice(0, 10),
        })
    },

    methods: {
        async getAnalytics(params) {
            this.pieRender = false
            try {
                this.loaded = false;

                let analytics = await this.ApiService.ticket.getPublicAnalytics(params, {}, {public: 1}).catch(e => {
                    if (e?.messages?.date_from) {
                        this.toast.error(e.messages.date_from)
                    }
                })
                this.loaded = true;

                if (analytics?.data.length) {
                    this.chartHasData = true

                    this.cinemas_count = analytics.cinemas_count;
                    this.halls_count = analytics.halls_count;
                    this.movies_count = analytics.movies_count;
                    this.tickets_amount = analytics.tickets_amount.toFixed(2);
                    this.tickets_count = analytics.tickets_count;
                    this.efficiency = (analytics.tickets_count / analytics.count_seats) * 100;
                    this.efficiency = this.efficiency.toFixed(2)

                    let uniqueSession = [...new Set(analytics.data.map(item => item.session))];
                    uniqueSession.sort();
                    uniqueSession = uniqueSession.map((e, index) => {
                        return {
                            id: index,
                            label: e
                        }
                    })
                    this.sessions_count = uniqueSession.length

                    let sortedUniqueCount = analytics.data.reduce(function (r, a) {
                        r[a.date] = r[a.date] || [];
                        r[a.date].push(a);
                        return r;
                    }, {})


                    let newDataSet = [];
                    for (const c in sortedUniqueCount) {
                        let summ = 0;
                        sortedUniqueCount[c].map((elem) => {
                            this.getSortByType == 'money' ? summ = summ + (+elem.amount_sum) : summ = summ + (+elem.count_sum)

                        })
                        newDataSet.push({ date: sortedUniqueCount[c][0].date, summ})
                    }

                    let datasetsMain = [];
                    let dataLabels = [];
                    newDataSet.sort((a, b) => (a.date > b.date) ? 1 : -1).map(e => {
                        datasetsMain.push(e.summ)
                        if (e.date.includes('/')) {
                            let weekNumber = e.date.substr(e.date.indexOf('/') + 1)
                            let monday = this.moment().day("Monday").week(+weekNumber);
                            dataLabels.push(this.moment(monday).format("YYYY-MM-DD"));
                        }
                        else {
                            dataLabels.push(e.date)
                        }
                    })

                    if (Object.keys(newDataSet).length == 1) {
                        dataLabels.push('')
                        datasetsMain.push(datasetsMain[0])
                    }

                    this.chartdata = {
                        labels: dataLabels,
                        datasets: [
                            {
                                data: datasetsMain,
                                label: '',
                                borderCapStyle: "string",
                                backgroundColor: '#FCF0F5',
                                borderColor: '#ed94bb',
                                pointBackgroundColor: '#2D5CA6',
                            }
                        ]
                    };

                    let cinemasAnalytic = Object.assign({}, analytics)
                    let hashMap = {};
                    analytics.data.forEach(item => {
                       if(!hashMap[item.cinema_id]) {
                           hashMap[item.cinema_id] = 0;
                       }
                        hashMap[item.cinema_id] += this.getSortByType == 'money' ? +item.amount_sum : +item.count_sum;
                    });
                    /*var results = [];
                    for (const c in sortedUniqueCount) {

                        sortedUniqueCount[c].map((elem) => {
                            let cinema_id = elem.cinema_id;


                            (async () => {
                                for await (const key of cinemasAnalytic.data) {
                                    if (key.cinema_id == cinema_id) {
                                        if (results.filter(elem => elem.cinema_id == cinema_id).length) {
                                            results.filter(elem => elem.cinema_id == cinema_id)[0].count_sum += +key.count_sum;
                                            console.log(key.cinema_id, results.filter(elem => elem.cinema_id == cinema_id)[0].count_sum);
                                            results.filter(elem => elem.cinema_id == cinema_id)[0].amount_sum += +key.amount_sum;
                                        }
                                        else {
                                            results.push({
                                                cinema_id: cinema_id,
                                                count_sum: +elem.count_sum,
                                                amount_sum: +elem.amount_sum,
                                                name: elem.name
                                            })
                                        }

                                    }
                                }
                            })()
                        })
                    }*/

                    setTimeout(() => {
                        (async () => {
                            let cinemasAnalytic = await this.ApiService.cinema.getCinemas({id: Object.keys(hashMap), no_pagination: true, public: 1}).catch(e => {})
                            hashMap = (Object.keys(hashMap)).map((id) => {
                                return {
                                    label: cinemasAnalytic.data.find(cinema => cinema.id == id)?.name,
                                    value: hashMap[id],
                                }
                            }).sort((a, b) => {
                                return (a.value < b.value) ? 1 : -1
                            });
                            /*let sorttedAnalyticData = results.sort((a, b) => {
                                if (this.getSortByType == 'money') {
                                    return (a.amount_sum < b.amount_sum) ? 1 : -1
                                }
                                else {
                                    return (a.count_sum < b.count_sum) ? 1 : -1
                                }
                            })*/

                       /*     let sorttedAnalyticDataPieLabels = sorttedAnalyticData.map(elem => elem.name)
                            let sorttedAnalyticDataPieData = sorttedAnalyticData.map(elem => {
                                return {
                                    label: elem.name,
                                    value: this.getSortByType == 'money' ? elem.amount_sum : elem.count_sum,
                                };
                            })*/


                            if (hashMap.length > 9) {
                                let others = hashMap.slice(9);
                                hashMap = hashMap.slice(0, 9)
                             /*   sorttedAnalyticDataPieLabels.push('Others')*/

                                let othersSum = 0;
                                others.forEach(o => othersSum += o.value)
                                others = {
                                    label: 'Others',
                                    value: othersSum
                                }
                                hashMap.push(others);
                              /*  sorttedAnalyticDataPieData = sorttedAnalyticDataPieData.slice(0, 9);
                                sorttedAnalyticDataPieData.push(othersSum)*/
                            }

                            this.chartdataPie = {
                                labels: hashMap.map(h => `${h.label} (${this.getSortByType == 'money' ? this.moneyFormat(h.value) : h.value})`),
                                datasets: [{
                                    data: hashMap.map(h => h.value),
                                    backgroundColor: [
                                        "#ffeaa2",
                                        "#8597ff",
                                        "#ff8298",
                                        "#a0d0ff",
                                        "#c0ff94",
                                        "#3896bc",
                                        "#fcbfa0",
                                        "#9497aa",
                                        "#76f3b8",
                                        '#00e0ff',
                                    ]
                                }]
                            }
                            this.pieRender = true
                        })()

                    }, 1000);


                }
                else {
                    this.chartHasData = false
                    this.chartdata = {
                        labels: [this.$t('January'), this.$t('February'), this.$t('March'), this.$t('April'), this.$t('May'), this.$t('June'), this.$t('July'), this.$t('August'), this.$t('September'), this.$t('October'), this.$t('November'), this.$t('December')],
                        datasets: [
                            {
                                data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
                                label: '',
                                borderCapStyle: "string",
                                backgroundColor: 'transparent',
                                borderColor: 'transparent',
                                pointBackgroundColor: 'transparent',
                            }
                        ]
                    };
                }
            } catch (e) {
                console.log(e);
            }
        },
        resizeWindow(){
            if (window.innerWidth < 993){
                this.optionsPie.legend.position = 'bottom'
            } else {
                this.optionsPie.legend.position = 'right'
            }
        }
    },
    created() {
        window.addEventListener("resize", this.resizeWindow)
        if (window.innerWidth < 993){
            this.optionsPie.legend.position = 'bottom'
        }
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeWindow)
    },
};
</script>


<style lang="scss" scoped>
    .chart-container {
        position: relative;

        &__stats {
            margin-top: 20px;
        }

        &__empty {
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            top: 120px;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                height: 100px;
            }

            p {
                font-size: 24px;
                font-weight: 600;
                text-align: center;
                color: #141213;
                margin-top: 10px;

                @media screen and (max-width: $sm) {
                    font-size: 14px;
                }
            }
        }

        &__statsItem {
            display: flex;
            align-items: center;
            padding: 10px 20px;
            background-color: $color_light;
            margin-bottom: 10px;
        }

        &__statsItemSquare {
            width: 40px;
            height: 40px;
            border-radius: 2px;
            background-color: $color_lightblue;
        }

        &__statsItemText {
            display: flex;
            flex-direction: column;
            margin-left: 16px;

            h4 {
                font-size: 16px;
                font-weight: 600;
                line-height: 1.69;
                letter-spacing: normal;
                color: $color_black;
                margin-bottom: 0;
            }

            h5 {
                font-size: 16px;
                color: $color_gray;
                margin-bottom: 0;
            }

        }

        &__donutCenter {
            position: absolute;
            top: 120px;
            left: 300px;
            font-size: 32px;
            font-weight: 600;
            text-align: center;
            color: #141213;
        }
    }
</style>
